<template>
  <HeaderMolecule
    :isLoading="isLoading"
    :selectedFleet="selectedFleet"
    :selectedMonth="selectedMonth"
    @select="$emit('set-filter', $event)"
  />
</template>

<script>
import HeaderMolecule from './HeaderMolecule'
import { DateTime } from 'luxon'
import { getMonthAndYearFormatted } from '@/utils/time'
import { getLocale } from '@/utils/locale'

export default {
  inject: ['uiSettings'],
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    selectedSubsidiaryId: {
      type: String,
    },
    selectedDayId: {
      type: String,
    },
    mySubsidiaries: {
      type: Array,
      required: true,
    },
  },
  components: {
    HeaderMolecule,
  },
  computed: {
    selectedMonth() {
      const locale = getLocale(this.uiSettings?.dates)
      return getMonthAndYearFormatted(DateTime.local().startOf('month').toISO(), locale)
    },
    selectedFleet() {
      const fleetOptions = this.mySubsidiaries.map(subsidiary => ({
        id: subsidiary.id,
        label: subsidiary.name,
        isSelected: subsidiary.id === this.selectedSubsidiaryId,
      }))
      return {
        id: 'fleet',
        value: fleetOptions.find(f => f.isSelected) ?? null,
        options: fleetOptions,
      }
    },
  },
}
</script>
