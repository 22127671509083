var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ListPageStyled', [_c('HeaderOrganism', {
    staticClass: "header",
    attrs: {
      "selectedSubsidiaryId": _vm.selectedSubsidiaryId,
      "selectedDayId": _vm.selectedDayId,
      "mySubsidiaries": _vm.mySubsidiaries,
      "isLoading": _vm.$apollo.queries.mySubsidiaries.loading
    },
    on: {
      "set-filter": _vm.setRouteQuery
    }
  }), _c('TableV2', {
    staticClass: "asset-list",
    attrs: {
      "title": _vm.$tc('asset', 2),
      "headers": _vm.headers,
      "rows": _vm.rows,
      "messages": _vm.messages,
      "isMobile": false,
      "sorting": _vm.sorting,
      "filtersAvailable": _vm.filtersAvailable,
      "filterOptionsSelected": _vm.filterOptionsSelected,
      "isLoading": _vm.$apollo.queries.mySickAssets.loading || _vm.$apollo.queries.mySubsidiaries.loading,
      "searchQuery": _vm.searchQuery,
      "enableSearch": true,
      "enableFooter": false,
      "showLoadMore": _vm.showLoadMore,
      "totalRowCount": _vm.mySickAssets.length
    },
    on: {
      "update:sorting": function updateSorting($event) {
        _vm.sorting = $event;
      },
      "textFilter": _vm.textSearch,
      "load-more-rows": _vm.loadMore
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }