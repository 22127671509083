<template>
  <FiltersMoleculeStyled>
    <ItemStyled>
      <span class="key">{{ $tc('maintenance.fleet', 1) }} </span>
      <FilterDropdownAtom
        v-if="!isLoading"
        :key="selectedFleet?.id + '_dropdown'"
        :filter="selectedFleet"
        @select="$emit('select', { id: $event.id, filter: selectedFleet?.id })"
      />
      <LoadingDots v-if="isLoading" class="loading" />
    </ItemStyled>
    <ItemStyled>
      <span class="key">{{ $tc('time', 1) | capitalize }} </span>
      <span class="value">{{ selectedMonth }}</span>
    </ItemStyled>
  </FiltersMoleculeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { LoadingDots } from '@common/components'
import chroma from 'chroma-js'

import FilterDropdownAtom from '../../SharedPages/HealthExplorer/HeaderMolecule/FiltersMolecule/FilterDropdownAtom'

const FiltersMoleculeStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-column-gap: 0.5rem;
  min-width: 17rem;
  font-size: 14px;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  box-shadow: ${({ theme }) => theme.colors.widgetShadow};
  background-color: ${({ theme }) =>
    theme.isDark ? chroma(theme.colors.solidBG).brighten(0.5).css() : chroma(theme.colors.widgetBG).darken(0.2).css()};
`

const ItemStyled = styled('div')`
  display: flex;
  width: max-content;
  align-items: center;
  .key {
    margin-right: 0.25rem;
    font-weight: bold;
  }
  .value {
    padding: 0.5rem;
  }
  .loading {
    margin: 1rem;
  }
`

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    selectedFleet: {
      type: Object,
      required: true,
    },
    selectedMonth: {
      type: String,
      required: true,
    },
  },

  components: {
    FiltersMoleculeStyled,
    ItemStyled,
    FilterDropdownAtom,
    LoadingDots,
  },
}
</script>
