<template>
  <HeaderMoleculeStyled>
    <HeartIconAtom :iconName="'HealthIcon'" />
    <TitleAtom :title="$t('navigation.cockpit.historicalAssetHealth')" />
    <FiltersMolecule
      :isLoading="isLoading"
      :selectedFleet="selectedFleet"
      :selectedMonth="selectedMonth"
      @select="$emit('select', $event)"
    />
  </HeaderMoleculeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import FiltersMolecule from './FiltersMolecule'
import TitleAtom from '@/components/Atomic/Atoms/TitleAtom'
import HeartIconAtom from '@/components/Atomic/Atoms/HeartIconAtom'

const HeaderMoleculeStyled = styled('div')`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-template-rows: 1fr;
  grid-gap: 1rem;
  align-items: center;
  background: ${({ theme }) => theme.colors.atomic.tableBG};
  border: 1px solid ${({ theme }) => theme.colors.mainBG};
  padding: 1rem;
  border-radius: 0.5rem;
`

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    selectedFleet: {
      type: Object,
      required: true,
    },
    selectedMonth: {
      type: String,
      required: true,
    },
  },
  components: {
    HeaderMoleculeStyled,
    TitleAtom,
    FiltersMolecule,
    HeartIconAtom,
  },
}
</script>
