<template>
  <HeartIconAtomStyled>
    <ToolIcon v-if="iconName === 'MaintenanceIcon'" />
    <TalpaIcon v-else class="icon" :scope="'Misc'" :name="iconName" />
  </HeartIconAtomStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { ToolIcon } from 'vue-feather-icons'
import { TalpaIcon } from '@common/components'

const HeartIconAtomStyled = styled('div')`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.atomic.textSecondary};
  background-color: ${({ theme }) => (theme.isDark ? theme.colors.atomic.healthStatusIconBG : theme.colors.whiteSmokeDark)};
  border-radius: 0.5rem;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${({ theme }) => theme.colors.widgetShadow};
  path {
    stroke: currentColor;
  }
`

export default {
  props: {
    iconName: {
      type: String,
      required: true,
    },
  },
  components: {
    HeartIconAtomStyled,
    ToolIcon,
    TalpaIcon,
  },
}
</script>
