var render = function () {
  var _vm$selectedFleet;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('FiltersMoleculeStyled', [_c('ItemStyled', [_c('span', {
    staticClass: "key"
  }, [_vm._v(_vm._s(_vm.$tc('maintenance.fleet', 1)) + " ")]), !_vm.isLoading ? _c('FilterDropdownAtom', {
    key: ((_vm$selectedFleet = _vm.selectedFleet) === null || _vm$selectedFleet === void 0 ? void 0 : _vm$selectedFleet.id) + '_dropdown',
    attrs: {
      "filter": _vm.selectedFleet
    },
    on: {
      "select": function select($event) {
        var _vm$selectedFleet2;
        return _vm.$emit('select', {
          id: $event.id,
          filter: (_vm$selectedFleet2 = _vm.selectedFleet) === null || _vm$selectedFleet2 === void 0 ? void 0 : _vm$selectedFleet2.id
        });
      }
    }
  }) : _vm._e(), _vm.isLoading ? _c('LoadingDots', {
    staticClass: "loading"
  }) : _vm._e()], 1), _c('ItemStyled', [_c('span', {
    staticClass: "key"
  }, [_vm._v(_vm._s(_vm._f("capitalize")(_vm.$tc('time', 1))) + " ")]), _c('span', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.selectedMonth))])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }