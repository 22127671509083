<template>
  <FilterDropdownAtomStyled>
    <CustomMultiSelect
      class="filter-dropdown"
      track-by="id"
      openDirection="bottom"
      :value="filter.value"
      :options="filter.options"
      :shouldNotResetSelectedOption="true"
      :disabled="filter.isDisabled"
      :closeOnSelect="true"
      :searchable="true"
      :multiple="false"
      :maxHeight="256"
      :optionsLimit="6"
      :applyFilter="false"
      :internalSearch="true"
      :customNoOptionsSlot="true"
      @select="$emit('select', $event)"
    >
      <template v-slot:noOptions>
        <strong>{{ $t('messages.noDataShort') }}</strong>
      </template>
    </CustomMultiSelect>
  </FilterDropdownAtomStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { CustomMultiSelect } from '@common/components'

// const ShowAsLabel = styled('span')`
//   color: ${({ theme }) => theme.colors.atomic.textMain};
// `

const FilterDropdownAtomStyled = styled('div')`
  display: flex;
  gap: 0.5rem;
  font-size: 14px;
  padding: 0 0.75rem 0 0.25rem;
`

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  components: {
    FilterDropdownAtomStyled,
    CustomMultiSelect,
    // ShowAsLabel,
  },
}
</script>
