<template>
  <TitleAtomStyled>
    <h4>{{ title }}</h4>
  </TitleAtomStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'

const TitleAtomStyled = styled('div')``
export default {
  components: {
    TitleAtomStyled,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
  },
}
</script>
