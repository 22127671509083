var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('HeartIconAtomStyled', [_vm.iconName === 'MaintenanceIcon' ? _c('ToolIcon') : _c('TalpaIcon', {
    staticClass: "icon",
    attrs: {
      "scope": 'Misc',
      "name": _vm.iconName
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }