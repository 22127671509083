<template>
  <ListPageStyled>
    <HeaderOrganism
      class="header"
      :selectedSubsidiaryId="selectedSubsidiaryId"
      :selectedDayId="selectedDayId"
      :mySubsidiaries="mySubsidiaries"
      @set-filter="setRouteQuery"
      :isLoading="$apollo.queries.mySubsidiaries.loading"
    />
    <TableV2
      class="asset-list"
      :title="$tc('asset', 2)"
      :headers="headers"
      :rows="rows"
      :messages="messages"
      :isMobile="false"
      :sorting.sync="sorting"
      :filtersAvailable="filtersAvailable"
      :filterOptionsSelected="filterOptionsSelected"
      :isLoading="$apollo.queries.mySickAssets.loading || $apollo.queries.mySubsidiaries.loading"
      :searchQuery="searchQuery"
      :enableSearch="true"
      :enableFooter="false"
      :showLoadMore="showLoadMore"
      :totalRowCount="mySickAssets.length"
      @textFilter="textSearch"
      @load-more-rows="loadMore"
    />
  </ListPageStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { TABLE_SORT_TYPES } from '@/constants/settings'
import { LoadingDots } from '@common/components'
import { DateTime } from 'luxon'

import HeaderOrganism from './Header/HeaderOrganism.vue'
import { orderBy, get, partition, concat } from 'lodash'
import { getCurrentMonthInterval } from '@/utils/time'
import { getLocale } from '@/utils/locale'
import { getAssetDimensionNameByLocale } from '@common/utils/src'

import TableV2 from '@/components/Atomic/Molecules/TableV2'
import fullTimeDurationFormatter from '@/utils/duration'
import TableIconCell from '@/components/Atomic/Atoms/TableCells/TableIconCell'
import TableRouterLinkCell from '@/components/Atomic/Atoms/TableCells/TableRouterLinkCell'
import AssetDimensionCell from '@/components/Atomic/Atoms/TableCells/AssetDimensionCell'

import MY_SUBSIDIARIES_QUERY from '#/graphql/operations/historicalAssetHealth/mySubsidiariesQuery.gql'
import MY_SICK_ASSETS_QUERY from '#/graphql/operations/historicalAssetHealth/mySickAssetsQuery.gql'
import ASSET_OPERATING_TIME from '#/graphql/assetDimensions/assetDimensionDataAggrByAsset.gql'
import MY_ASSETS_LAST_UPDATES_QUERY from '#/graphql/operations/assets/minimals/myAssetsLastUpdate.gql'

const ListPageStyled = styled('div')`
  padding: 0.5rem;
  height: calc(100% - 1rem);
  overflow: hidden;
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-gap: 1rem;
  > .asset-list {
    width: 100%;
    height: calc(100% - 1rem);
    overflow-y: auto;
  }
  @media (min-width: 768px) {
    padding: 1rem;
    height: calc(100% - 2rem);
  }
`

export default {
  inject: ['uiSettings'],
  components: {
    ListPageStyled,
    HeaderOrganism,
    TableV2,
  },
  data() {
    return {
      filtersAvailable: [],
      filterOptionsSelected: [],
      batchSize: 100, // Number of items to load initially
      loadedBatch: 0,
      totalCount: 0,
      searchQuery: '',
      sorting: {
        key: 'sickDuration',
        asc: true,
      },
      limit: 50,
      offset: 0,
      mySickAssets: [],
      mySubsidiaries: [],
      assetsLastUpdateMap: new Map(),
      assetDimensionDataMap: new Map(),
    }
  },
  computed: {
    selectedTimeframe() {
      const timeFrame = getCurrentMonthInterval()
      const granularity = timeFrame?.length('days') > 2 ? 'P1D' : 'PT1H'
      return {
        start: timeFrame.start,
        end: timeFrame.end,
        timezone: DateTime.local().zoneName,
        granularity,
        shifts: [],
      }
    },
    locale() {
      const userLocale = this.uiSettings?.dates ?? 'DE_DE'
      const replacedDates = userLocale.replace('_', '-')
      return replacedDates.slice(0, 2)
    },
    selectedSubsidiaryId() {
      const byRoute = this.mySubsidiaries?.find(f => f.id === this.$route?.query?.fleet)
      if (byRoute) {
        return byRoute.id
      }
      return this.mySubsidiaries?.[0]?.id ?? null
    },
    selectedDayId() {
      return this.$route?.query?.daysFromNow ?? '0'
    },
    messages() {
      let message = ''
      if (this.rows.length === 0) {
        message = this.searchQuery === '' ? this.$tc('messages.noData') : this.$t('messages.searchNoResults', { query: this.searchQuery })
      }
      return message
    },
    operatingHoursLabel() {
      const kpiLocale = this.locale?.slice(0, 2)
      return this.assetDimension?.nameTranslations
        ? getAssetDimensionNameByLocale(this.assetDimensions.nameTranslations, kpiLocale)
        : this.assetDimension?.name
    },
    headers() {
      const headers = [
        {
          id: `header_type`,
          label: this.$tc('type', 1),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'type.name',
          size: 'xsmall',
        },
        {
          id: `header_name`,
          label: this.$tc('asset', 1),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'name',
          size: 'medium',
        },
        {
          id: `header_sickDuration`,
          label: this.$tc('historicalAssetHealth.sickHours', 1),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'sickDuration',
          size: 'small',
          unit: 'h',
        },
        {
          id: `header_operatingHours`,
          label: this.$tc('historicalAssetHealth.operationTime', 1),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'operatingHours',
          size: 'small',
          unit: 'h',
        },
        {
          id: `header_totalMachineHours`,
          label: this.$tc('totalMachineHours', 1),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'totalMachineHours',
          size: 'medium',
          unit: 'h',
        },
      ]
      return headers
    },
    mappedAssets() {
      return this.mySickAssets.map(sickAsset => {
        const assetDimensionData = this.assetDimensionDataMap.get(sickAsset.assetId)
        const lastUpdateData = this.assetsLastUpdateMap.get(sickAsset.assetId)
        return {
          ...sickAsset.asset,
          sickDuration: sickAsset?.sickDuration,
          operatingHours: assetDimensionData?.floatValue,
          totalMachineHours: lastUpdateData?.totalMachineHours,
        }
      })
    },
    assetsSorted() {
      const sortingKey = this.sorting.key
      const partitions = partition(this.mappedAssets, x => !!get(x, sortingKey, null))
      const sortDirection = this.sorting.asc ? 'desc' : 'asc'
      const sortedPartitions = partitions.map(partition => orderBy(partition, [sortingKey], [sortDirection]))
      const result = concat(...sortedPartitions)
      this.resetBatch()
      return result
    },
    rows() {
      const rows = this.assetsSorted.map(asset => {
        let cells = [
          {
            id: `${asset.id}_type`,
            sortableValue: asset?.type?.name,
            component: this.$apollo.queries.mySickAssets.loading ? LoadingDots : TableIconCell,
            icon: asset.type?.name?.split(' ').join(''),
            headerId: 'header_type',
          },
          {
            id: `${asset.id}_name`,
            sortableValue: asset?.name,
            component: this.$apollo.queries.mySickAssets.loading ? LoadingDots : TableRouterLinkCell,
            linkName: 'HistoricalAssetHealthOverview',
            linkLabel: asset?.name,
            linkParams: { id: asset.id },
            isMobilePrimarycol: true,
            mobilePrimarycolLabel: asset?.name,
            headerId: `header_name`,
          },
          {
            id: `${asset.id}_duration`,
            sortableValue: asset?.sickDuration ? fullTimeDurationFormatter(asset?.sickDuration) : '-',
            headerId: `header_sickDuration`,
          },
          {
            id: `${asset.id}_operatingHours`,
            sortableValue: asset?.operatingHours,
            value: asset?.operatingHours,
            component: this.$apollo.queries.assetDimensionData.loading ? LoadingDots : AssetDimensionCell,
            headerId: `header_operatingHours`,
          },
          {
            id: `${asset.id}_totalMachineHours`,
            sortableValue: asset?.totalMachineHours,
            value: asset?.totalMachineHours === 0 ? null : asset?.totalMachineHours,
            component: AssetDimensionCell,
            headerId: `header_totalMachineHours`,
          },
        ]
        return {
          rowId: `row_${asset.id}`,
          cells,
        }
      })

      let filteredTable = rows
      if (this.searchQuery !== '') {
        filteredTable = rows.filter(row => {
          const searchQueryLower = this.searchQuery.toLowerCase()
          return row.cells.some(item => {
            const sortableValue = item.sortableValue
            if (sortableValue !== undefined && sortableValue !== null) {
              const prop = typeof sortableValue === 'string' ? sortableValue.toLowerCase() : sortableValue.toString(10)
              return prop.includes(searchQueryLower)
            }
            return false
          })
        })
      }
      this.setCounter(filteredTable.length)
      return filteredTable.slice(0, this.batchSize * (this.loadedBatch + 1))
    },
    showLoadMore() {
      return this.rows.length < this.totalCount
    },
    assetsInSubsidiary() {
      if (this.mySubsidiaries.length < 1) {
        return []
      }
      const selectedSubsidiary = this.mySubsidiaries?.filter(f => f.id === this.selectedSubsidiaryId)
      const assetsIds = selectedSubsidiary[0].assets.map(f => f.id)
      return assetsIds
    },
  },
  methods: {
    setRouteQuery({ filter, id }) {
      if (filter === 'fleet') {
        this.mySickAssets = []
        this.$router.push({
          query: {
            ...this.$route.query,
            fleet: id,
          },
        })
      }
    },
    setCounter(count) {
      this.totalCount = count
    },
    loadMore() {
      this.loadedBatch++
    },
    textSearch(query) {
      this.searchQuery = query
      this.resetBatch()
    },
    resetBatch() {
      this.loadedBatch = 0
    },
  },
  apollo: {
    mySubsidiaries: {
      query: MY_SUBSIDIARIES_QUERY,
    },
    assetDimensionData: {
      query: ASSET_OPERATING_TIME,
      variables() {
        return {
          where: {
            assetDimension: {
              name: 'operation_time',
            },
            timeframe: this.selectedTimeframe,
            assets: {
              id_in: this.assetsInSubsidiary,
            },
          },
        }
      },
      skip() {
        return this.assetsInSubsidiary.length < 1 || !this.selectedTimeframe
      },
      result({ data }) {
        this.assetDimensionDataMap = new Map(data.assetDimensionData.aggrByAsset.map(data => [data.assetId, data]))
      },
    },
    myAssetsLastUpdate: {
      query: MY_ASSETS_LAST_UPDATES_QUERY,
      variables() {
        return {
          assetIds: this.assetsInSubsidiary,
        }
      },
      skip() {
        return this.assetsInSubsidiary.length < 1
      },
      result({ data }) {
        this.assetsLastUpdateMap = new Map(data.myAssetsLastUpdate.map(asset => [asset.assetId, asset]))
      },
    },
    mySickAssets: {
      query: MY_SICK_ASSETS_QUERY,
      variables() {
        return {
          where: {
            id_in: this.assetsInSubsidiary,
            interval: getCurrentMonthInterval().toISO(),
            locale: getLocale(this.uiSettings.dates),
            pagination: {
              limit: this.assetsInSubsidiary.length,
              offset: this.offset,
            },
          },
        }
      },
      skip() {
        return !this.selectedSubsidiaryId || this.assetsInSubsidiary.length < 1
      },
    },
  },
}
</script>
