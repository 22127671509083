var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('FilterDropdownAtomStyled', [_c('CustomMultiSelect', {
    staticClass: "filter-dropdown",
    attrs: {
      "track-by": "id",
      "openDirection": "bottom",
      "value": _vm.filter.value,
      "options": _vm.filter.options,
      "shouldNotResetSelectedOption": true,
      "disabled": _vm.filter.isDisabled,
      "closeOnSelect": true,
      "searchable": true,
      "multiple": false,
      "maxHeight": 256,
      "optionsLimit": 6,
      "applyFilter": false,
      "internalSearch": true,
      "customNoOptionsSlot": true
    },
    on: {
      "select": function select($event) {
        return _vm.$emit('select', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "noOptions",
      fn: function fn() {
        return [_c('strong', [_vm._v(_vm._s(_vm.$t('messages.noDataShort')))])];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }