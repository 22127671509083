var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('HeaderMoleculeStyled', [_c('HeartIconAtom', {
    attrs: {
      "iconName": 'HealthIcon'
    }
  }), _c('TitleAtom', {
    attrs: {
      "title": _vm.$t('navigation.cockpit.historicalAssetHealth')
    }
  }), _c('FiltersMolecule', {
    attrs: {
      "isLoading": _vm.isLoading,
      "selectedFleet": _vm.selectedFleet,
      "selectedMonth": _vm.selectedMonth
    },
    on: {
      "select": function select($event) {
        return _vm.$emit('select', $event);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }